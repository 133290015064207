import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>SparkleWorks Cleaning Co.</title>
        <meta
          name={"description"}
          content={"Освітлюйте свій робочий простір, один рух за раз"}
        />
        <meta property={"og:title"} content={"SparkleWorks Cleaning Co."} />
        <meta
          property={"og:description"}
          content={"Освітлюйте свій робочий простір, один рух за раз"}
        />
        <meta
          property={"og:image"}
          content={"https://win.povixol.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://win.povixol.com/img/medal.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://win.povixol.com/img/medal.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://win.povixol.com/img/medal.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://win.povixol.com/img/medal.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://win.povixol.com/img/medal.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://win.povixol.com/img/medal.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 390px 0" quarkly-title="Contacts-3">
        <Text
          padding="0px 0px 50px 0px"
          sm-font="normal 700 36px/1.2 --fontFamily-sans"
          margin="0px 0px 18px 0px"
          font="normal 600 42px/1.2 --fontFamily-sans"
          color="--darkL1"
          text-align="left"
          lg-width="100%"
          lg-margin="0px 0px 25px 0px"
        >
          Контакти
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="36px 34px"
          lg-grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="1fr"
        >
          <Box
            min-width="100px"
            min-height="100px"
            padding="0px 0px 0px 18px"
            border-width="0 0 0 2px"
            border-style="solid"
            border-color="--color-lightD2"
          >
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 22px/1.2 --fontFamily-sans"
            >
              Локація
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
              color="#60666d"
            >
              вул. Київська 63, Новосілки, 07362
            </Text>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            padding="0px 0px 0px 18px"
            border-width="0 0 0 2px"
            border-style="solid"
            border-color="--color-lightD2"
          >
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 22px/1.2 --fontFamily-sans"
            >
              Телефон
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
              color="#60666d"
            >
              +38 063 474 03 24
            </Text>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            padding="0px 0px 0px 18px"
            border-width="0 0 0 2px"
            border-style="solid"
            border-color="--color-lightD2"
          >
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 22px/1.2 --fontFamily-sans"
            >
              Email
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
              color="#60666d"
            >
              offer@win.povixol.com
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
